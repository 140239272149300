// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderProjectProposalFormScss from "./ProviderProjectProposalForm.scss";

var css = ProviderProjectProposalFormScss;

function ProviderProjectProposalForm_DescriptionSection(props) {
  var setDescription = props.setDescription;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Describe the Proposal (Optional)"
                    }),
                JsxRuntime.jsx(Textarea.make, {
                      id: "proposal-description",
                      value: props.description,
                      rows: 4,
                      className: css.proposalDescriptionTextarea,
                      onChange: (function ($$event) {
                          setDescription($$event.target.value);
                        })
                    })
              ],
              className: css.proposalDescriptionSection
            });
}

var make = ProviderProjectProposalForm_DescriptionSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
