// extracted by mini-css-extract-plugin
export var additionalUploader = "ProviderProjectProposalForm__additionalUploader__uB14Z";
export var colLeft = "ProviderProjectProposalForm__colLeft__TFGfx";
export var column = "ProviderProjectProposalForm__column__m_9Ly";
export var container = "ProviderProjectProposalForm__container__EYiBP";
export var contentWrapper = "ProviderProjectProposalForm__contentWrapper__lTTV0";
export var datePickerHeader = "ProviderProjectProposalForm__datePickerHeader__HjqaH";
export var deleteIcon = "ProviderProjectProposalForm__deleteIcon__o6yMC";
export var documentRow = "ProviderProjectProposalForm__documentRow__naAg0";
export var documentsSectionHeading = "ProviderProjectProposalForm__documentsSectionHeading__vcczO";
export var expirationDateSelectionContainer = "ProviderProjectProposalForm__expirationDateSelectionContainer__kZy6D";
export var fileNameText = "ProviderProjectProposalForm__fileNameText__HPi1R";
export var fileSizeText = "ProviderProjectProposalForm__fileSizeText__I3cya";
export var flexContainer = "ProviderProjectProposalForm__flexContainer__H2r8X";
export var flexOne = "ProviderProjectProposalForm__flexOne__OSzjU";
export var flexTwo = "ProviderProjectProposalForm__flexTwo__NMjvf";
export var headingText = "ProviderProjectProposalForm__headingText__afVXn";
export var icon = "ProviderProjectProposalForm__icon__kRiQu";
export var iconAndNameContainer = "ProviderProjectProposalForm__iconAndNameContainer__kESyz";
export var iconDelete = "ProviderProjectProposalForm__iconDelete__dbFLb";
export var iconView = "ProviderProjectProposalForm__iconView__cqoq7";
export var inlineSpacer = "ProviderProjectProposalForm__inlineSpacer__CuqDP";
export var notEditableMessage = "ProviderProjectProposalForm__notEditableMessage___r_fI";
export var paragraphText = "ProviderProjectProposalForm__paragraphText__c9cVo";
export var proposalDescriptionSection = "ProviderProjectProposalForm__proposalDescriptionSection__FstWC";
export var proposalDescriptionTextarea = "ProviderProjectProposalForm__proposalDescriptionTextarea__HvNep";
export var row = "ProviderProjectProposalForm__row__s7Lz9";
export var secondaryHeadingText = "ProviderProjectProposalForm__secondaryHeadingText__A05G8";
export var selectProviderSectionContainer = "ProviderProjectProposalForm__selectProviderSectionContainer__xCwdw";
export var selectedDocumentContainer = "ProviderProjectProposalForm__selectedDocumentContainer__mZmxJ";
export var selectedFileNoteText = "ProviderProjectProposalForm__selectedFileNoteText__L8Cx2";
export var sendProposalButton = "ProviderProjectProposalForm__sendProposalButton__PjlOc";
export var sendProposalText = "ProviderProjectProposalForm__sendProposalText__w4sXV";
export var uploader = "ProviderProjectProposalForm__uploader__Jeb7N";
export var uploaderInner = "ProviderProjectProposalForm__uploaderInner__ZSPLZ";